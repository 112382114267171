import { FC } from 'react';
import IconProps from './IconProps';

const SortDescendingIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.999929 8.10623e-05C0.447644 8.10623e-05 -7.10487e-05 0.447796 -7.10487e-05 1.00008C-7.10487e-05 1.55237 0.447644 2.00008 0.999929 2.00008V8.10623e-05ZM13.9999 2.00008C14.5522 2.00008 14.9999 1.55237 14.9999 1.00008C14.9999 0.447796 14.5522 8.10623e-05 13.9999 8.10623e-05V2.00008ZM0.999929 4.00005C0.447644 4.00005 -7.10487e-05 4.44777 -7.10487e-05 5.00005C-7.10487e-05 5.55234 0.447644 6.00005 0.999929 6.00005V4.00005ZM9.99993 6.00005C10.5522 6.00005 10.9999 5.55234 10.9999 5.00005C10.9999 4.44777 10.5522 4.00005 9.99993 4.00005V6.00005ZM0.999929 8.00028C0.447644 8.00028 -7.10487e-05 8.448 -7.10487e-05 9.00028C-7.10487e-05 9.55257 0.447644 10.0003 0.999929 10.0003V8.00028ZM9.99993 10.0003C10.5522 10.0003 10.9999 9.55257 10.9999 9.00028C10.9999 8.448 10.5522 8.00028 9.99993 8.00028V10.0003ZM15.9999 5.00014C15.9999 4.44786 15.5521 4.00014 14.9999 4.00014C14.4476 4.00014 13.9999 4.44786 13.9999 5.00014H15.9999ZM13.9999 17.0001C13.9999 17.5524 14.4476 18.0001 14.9999 18.0001C15.5521 18.0001 15.9999 17.5524 15.9999 17.0001H13.9999ZM14.2927 17.7072C14.6833 18.0977 15.3164 18.0977 15.707 17.7072C16.0975 17.3167 16.0975 16.6835 15.707 16.293L14.2927 17.7072ZM11.707 12.293C11.3164 11.9024 10.6833 11.9024 10.2927 12.293C9.90222 12.6835 9.90222 13.3167 10.2927 13.7072L11.707 12.293ZM14.2928 16.293C13.9023 16.6835 13.9023 17.3167 14.2928 17.7072C14.6834 18.0977 15.3165 18.0977 15.7071 17.7072L14.2928 16.293ZM19.7071 13.7072C20.0976 13.3167 20.0976 12.6835 19.7071 12.293C19.3165 11.9024 18.6834 11.9024 18.2928 12.293L19.7071 13.7072ZM0.999929 2.00008H13.9999V8.10623e-05H0.999929V2.00008ZM0.999929 6.00005H9.99993V4.00005H0.999929V6.00005ZM0.999929 10.0003H9.99993V8.00028H0.999929V10.0003ZM13.9999 5.00014V17.0001H15.9999V5.00014H13.9999ZM15.707 16.293L11.707 12.293L10.2927 13.7072L14.2927 17.7072L15.707 16.293ZM15.7071 17.7072L19.7071 13.7072L18.2928 12.293L14.2928 16.293L15.7071 17.7072Z"
        fill="#3E2234"
      />
    </svg>
  );
};

export default SortDescendingIcon;
